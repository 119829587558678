import { useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import LicensesPopup from '../../components/licenses-popup/licenses-popup'
import { breadcrumbs } from '../../const'
import MyImage from '../../components/my-image/my-image'

function Licenses({isMobile} ) {
  const [activePopup, setActivePopup] = useState(0)
  if (activePopup === 0) {
    window.scrollTo(0,0)
  }
  const N = 8
  const arrayImg = Array.from(Array(N + 1).keys()).slice(1)

  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.licenses} />
      <div className="container">
        <h1 className="pictures__title">ЛИЦЕНЗИИ</h1>
        <div className="pictures__wrapper">
          {arrayImg.map((item) => (
            <div
              className="pictures"
              key={item}
              onClick={() => setActivePopup(item)}
            >
              <MyImage
                cl={"picture"}
                src={!isMobile ? `img/licenses/licenses-` + item + `1.jpg` : `img/licenses/licenses-` + item + `1.webp`}
                placeholder={`img/licenses/licenses-` + item + `1-small.jpg`}
                alt="license"
              />
            </div>
          ))}
        </div>
      </div>
      <Footer isMobile={isMobile}  />
      {activePopup && !isMobile ? (
        <LicensesPopup
          activePopup={activePopup}
          setActivePopup={setActivePopup}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Licenses
