import { useEffect, useState } from 'react'
import { licensesList } from '../../const'

function LicensesPopup({ activePopup, setActivePopup }) {
  const [activeImage, setActiveImage] = useState(1)

  useEffect(() => {
      const handleKeyDown = (event) => {
          if (event.key === "ArrowRight" && licensesList[activePopup][activeImage]) {
            setActiveImage(activeImage + 1)
          } else if (event.key === "ArrowLeft" && activeImage >1) {
            setActiveImage(activeImage - 1)
          }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
          window.removeEventListener("keydown", handleKeyDown);
      };
  }, [activeImage]);

  return (
    <div
      className={activePopup ? `licenses-popup active` : `licenses-popup`}
      onClick={() => {
        setActiveImage(1)
        setActivePopup(false)
      }}
    >
      {activeImage > 1 ? (
        <div
          className="slider__left--arrow popup"
          onClick={(e) => {
            e.stopPropagation()
            setActiveImage(activeImage - 1)
          }}
        >
          <button className="button popup">
          ❰
          </button>
        </div>
      ) : (
        ''
      )}
      {licensesList[activePopup][activeImage] ? (
        <div
          className="slider__right--arrow popup"
          onClick={(e) => {
            e.stopPropagation()
            setActiveImage(activeImage + 1)
          }}
        >
          <button className="button popup">
          ❱
          </button>
        </div>
      ) : (
        ''
      )}

      <img
        onClick={(e) => e.stopPropagation()}
        className={
          activePopup ? `license-popup__img active` : `license-popup__img`
        }
        src={`img/licenses/` + licensesList[activePopup][activeImage - 1]}
        alt="license-popup"
      />
    </div>
  )
}

export default LicensesPopup
